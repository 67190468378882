import React, {useEffect} from 'react';
import Card from '../../../common/components/Card/Card';
import './Dashboard.scss';
import iconMunicipio from '../../../assets/icons/municipio.png';
import iconSolucoes from '../../../assets/icons/solucoes.png';
import iconInvestimento from '../../../assets/icons/estimativa.png';
import iconInqueritos from '../../../assets/icons/inqueritos.png';
import banner1 from '../../../assets/media/private/banner1.jpg'
import banner2 from '../../../assets/media/private/banner2.png'
import banner3 from '../../../assets/media/private/banner3.jpg'
import banner4 from '../../../assets/media/private/banner4.jpg'
import iconGraduado from '../../../assets/icons/graduated.png';
import iconCar from '../../../assets/icons/car.png';
import iconBicicleta from '../../../assets/icons/bycicle.png';
import iconWalk from '../../../assets/icons/walk.png';
import iconBusStop from '../../../assets/icons/bus-stop.png';

import {Typography} from '@mui/material';
import {ButtonLink} from '../../../common/utilities/ButtonLink';

const Dashboard = () => {
    useEffect(() => {
        document.body.className = "page-dashboard";

        return () => {
            document.body.className = "";
        }
    }, []);

    return (
        <div className={"dashboard"}>
            <section id={"section1"} className={"cards-wrapper"}>
                <Card icon={{alt: "", src: iconGraduado}} textAlign={'center'} title={{tag: 'h3', text: window.mobesc.DASH_01}}
                      desc={'alunos'}/>
                <Card icon={{alt: "", src: iconCar}} textAlign={'center'} title={{tag: 'h3', text: window.mobesc.DASH_02}}
                      desc={'vão de carro para a escola'}/>
                <Card icon={{alt: "", src: iconBicicleta}} textAlign={'center'} title={{tag: 'h3', text: window.mobesc.DASH_03}}
                      desc={'vão de bicicleta'}/>
                <Card icon={{alt: "", src: iconWalk}} textAlign={'center'} title={{tag: 'h3', text: window.mobesc.DASH_04}}
                      desc={'vão a pé para a escola'}/>
                <Card icon={{alt: "", src: iconBusStop}} textAlign={'center'} title={{tag: 'h3', text: window.mobesc.DASH_05}}
                      desc={'vão de transporte público'}/>
            </section>

            <section id={"section3"} className={"cards-wrapper"}>
                <Card icon={{alt: "", src: iconMunicipio}} backgroundColor={"#98C997"} textAlign={'center'}
                      title={{tag: 'h4', text: 'Municípios'}}
                      desc={'Um melhor conhecimento favorece um melhor planeamento'}/>
                <Card icon={{alt: "", src: iconSolucoes}} backgroundColor={"#DD7E55"} textAlign={'center'}
                      title={{tag: 'h4', text: 'Soluções'}}
                      desc={'Diversas soluções possíveis, em função das especificidades de cada escola'}/>
                <Card icon={{alt: "", src: iconInvestimento}} backgroundColor={"#D48DED"} textAlign={'center'}
                      title={{tag: 'h4', text: 'Investimento'}}
                      desc={'Canalizar o investimento para as soluções que melhor se adaptam'}/>
                <Card icon={{alt: "", src: iconInqueritos}} backgroundColor={"#8ACCE1"} textAlign={'center'}
                      title={{tag: 'h4', text: 'Inquéritos'}}
                      desc={'Compreender e acompanhar a mudança.'}/>
            </section>

            {/* <section id={"section2"}>
                <div className={"grid-wrapper"}>
                    <div className={"col c-1"}>
                        <div className={"content"}>
                            <img src={banner2} alt={""}/>
                            <div className={"desc"}>
                                <Typography className={"text"}>700 CRIANÇAS DESFILAM NAS MARCHAS INFANTIS DE LISBOA</Typography>
                            </div>
                        </div>
                    </div>
                    <div className={"col c-2"}>
                        <div className={"content -center"}>
                            <img src={banner1} alt={""} style={{borderRadius: "10px"}}/>
                            <div className={"desc"}>
                                <Typography className={"text"}>DESLOCAÇÕES A PÉ PARA A ESCOLA AUMENTAM EM 2021</Typography>
                            </div>
                        </div>
                        <div className={"content -center"}>
                            <img src={banner4} alt={""} style={{borderRadius: "10px"}}/>
                            <div className={"desc"}>
                                <Typography className={"text"}>LISBOA RECEBEU A 9ª EDIÇÃO DAS ESCOLAS EM CONCERTO</Typography>
                            </div>
                        </div>
                    </div>
                    <div className={"col c-3"}>
                        <div className={"content"}>
                            <img src={banner3} alt={""}/>
                            <div className={"desc"}>
                                <Typography className={"text"}>ATRIBUIÇÃO DE APOIOS PELO MUNICÍPIO DE LISBOA</Typography>
                                <ButtonLink link={"/dashboard"} variant={"outlined"} className={"secondary"} text={"Saber Mais"} />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </div>
    );
}

export default Dashboard;
